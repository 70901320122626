const OKX_WALLET_ENABLED =
  import.meta.env.VITE_FEATURE_FLAG_OKX_WALLET_ENABLED === "true"

const XVERSE_WALLET_ENABLED =
  import.meta.env.VITE_FEATURE_FLAG_XVERSE_WALLET_ENABLED === "true"

const WITHDRAWALS_ENABLED =
  import.meta.env.VITE_FEATURE_FLAG_WITHDRAWALS_ENABLED === "true"

const ACRE_POINTS_ENABLED =
  import.meta.env.VITE_FEATURE_FLAG_ACRE_POINTS_ENABLED === "true"

const TVL_ENABLED = import.meta.env.VITE_FEATURE_FLAG_TVL_ENABLED === "true"

const DEPOSIT_CAP_ENABLED =
  import.meta.env.VITE_FEATURE_DEPOSIT_CAP_ENABLED === "true"

const GATING_DAPP_ENABLED =
  import.meta.env.VITE_FEATURE_GATING_DAPP_ENABLED === "true"

const POSTHOG_ENABLED = import.meta.env.VITE_FEATURE_POSTHOG_ENABLED === "true"

const MOBILE_MODE_ENABLED =
  import.meta.env.VITE_FEATURE_MOBILE_MODE_ENABLED === "true"

const featureFlags = {
  OKX_WALLET_ENABLED,
  XVERSE_WALLET_ENABLED,
  WITHDRAWALS_ENABLED,
  ACRE_POINTS_ENABLED,
  TVL_ENABLED,
  DEPOSIT_CAP_ENABLED,
  GATING_DAPP_ENABLED,
  POSTHOG_ENABLED,
  MOBILE_MODE_ENABLED,
}

export default featureFlags
